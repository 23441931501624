(function () {
  'use strict';
  angular
  .module('app')
  .factory('globalService', globalService);

  globalService.$inject = ['$http', 'url', 'user', '$window', '$state','$resource'];

  function globalService($http, url, $user, $window, $state, $resource) {

    function hashToParams(params){
      var params_url = '';

      Object.keys(params).forEach(function(item, index) {
        if (index == 0 && params[item] != null) {
          params_url += '?' + item + '=' + params[item];
        } else if (index != 0 && params[item] != null) {
          params_url += '&' + item + '=' + params[item];
        }
      });

      return params_url;
    }

    function getSystemVersion() {
      return $http.get(url.getApiUrl('system_version'))
      .then(function(res){
        return res.data;
      })
    }
    function updateSystemVersion(data) {
      return $http.post(url.getApiUrl('system_version'), data)
      .then(function(res){
        return res.data;
      })
    }

    function getBancos(){
      return $http.get(url.getApiUrl('miscelaneos/bancos'))
      .then(function(res){
        return res.data;
      })
    }

    function getConfigCoupons(){
      return $http.get(url.getApiUrl('config_coupons'))
      .then(function(res){
        return res.data;
      })
    }
    function saveConfigCoupons(data){
      return $http.post(url.getApiUrl('config_coupons'), data)
      .then(function(res){
        return res.data;
      })
    }
    function updateConfigCoupons(id, data){
      return $http.put(url.getApiUrl('config_coupons/'+id), data)
      .then(function(res){
        return res.data;
      })
    }

    function getReportCoupons(data){
      return $http.get(url.getApiUrl('reports/report_coupons'))
      .then(function(res){
        return res.data;
      })
    }
    function getResumenCajero(data){
      return $http.get(url.getApiUrl('reports/reporte_resumen_cajero_web'))
      .then(function(res){
        return res.data;
      })
    }

    function getRequestNoVerify(data){
      return $http.get(url.getApiUrl('solicitud_operacion/get_request_no_verify'+hashToParams(data)))
      .then(function(res){
        return res.data;
      })
    }

    function verifyRequest(data){
      return $http.post(url.getApiUrl('solicitud_operacion/'+data.id+'/verify_request'), data)
      .then(function(res){
        return res.data;
      })
    }

    function getDefaultRequest(){
      return $http.get(url.getApiUrl('default_solicitud_operacion'))
      .then(function(res){
        return res.data;
      })
    }
    function saveDefaultRequest(data){
      return $http.post(url.getApiUrl('default_solicitud_operacion'), data)
      .then(function(res){
        return res.data;
      })
    }

    function getCouponsTypes(){
      return $http.get(url.getApiUrl('miscelaneos/coupons_types'))
      .then(function(res){
        return res.data;
      })
    }

    function getCountsUsers(){
      return $http.get(url.getApiUrl('home'))
      .then(function(res){
        return res.data;
      })
    }
    function getNoVerifyAgents(){
      return $http.get(url.getApiUrl('estructura/get_no_verify_agents'))
      .then(function(res){
        return res.data;
      })
    }
    function verifyAgent(data){
      return $http.post(url.getApiUrl('estructura/verify_agent'), data)
      .then(function(res){
        return res.data;
      })
    }
    function suspendAgent(data){
      return $http.post(url.getApiUrl('estructura/suspend_agent'), data)
      .then(function(res){
        return res.data;
      })
    }
    function sendMessage(data){
      return $http.post(url.getApiUrl('estructura/send_email_to_user'), data)
      .then(function(res){
        return res.data;
      })
    }
    function sendMessageAllCustomers(data){
      return $http.post(url.getApiUrl('estructura/send_bonos_to_all_users'), data)
      .then(function(res){
        return res.data;
      })
    }
    function getCustomers(data){
      var tipo = data || 4;
      return $http.get(url.getApiUrl('estructura?tipo='+tipo))
      .then(function(res){
        return res.data;
      })
    }
    function getLastOperaciones(data){
      return $http.get(url.getApiUrl('estructura/get_last_transactions'+hashToParams(data)))
      .then(function(res){
        return res.data;
      })
    }
    function getEmailsNotifications(){
      return $http.get(url.getApiUrl('emails_notifications'))
      .then(function(res){
        return res.data;
      })
    }
    function toggleStatusEmailsNotifications(data){
      return $http.post(url.getApiUrl('emails_notifications/toggle_status'), data)
      .then(function(res){
        return res.data;
      })
    }
    function updateNotificationtime(data){
      return $http.put(url.getApiUrl('emails_notifications/'+data.id), data)
      .then(function(res){
        return res.data;
      })
    }
    function getCouponsBank(){
      return $http.get(url.getApiUrl('coupon_bank'))
      .then(function(res){
        return res.data;
      })
    }
    function saveCouponBank(data){
      return $http.post(url.getApiUrl('coupon_bank'), data)
      .then(function(res){
        return res.data;
      })
    }

    function updateCouponBank(data){
      return $http.put(url.getApiUrl('coupon_bank/'+data.id), data)
      .then(function(res){
        return res.data;
      })
    }
    function getGames(){
      return $http.get(url.getApiUrl('games'))
      .then(function(res){
        return res.data;
      })
    }
    function updateGame(data){
      return $http.put(url.getApiUrl('games/'+data.id), data)
      .then(function(res){
        return res.data;
      })
    }

    function sendDataAutomatic(data){
      return $http.post(url.getApiUrl('solicitud_operacion/process_automatic_request'), data)
      .then(function(res){
        return res.data;
      })
    }

    function reverseOperation(data){
      
      return $http.post(url.getApiUrl('cajero_web/reverse_operacion'), data)
      .then(function(res){
        return res.data;
      })
    }

    function getCajeroWeb(id){
      return $http.get(url.getApiUrl('cajero_web/'+id))
      .then(function(res){
        return res.data;
      })
    }

    function getCustomerPlays(data){
      return $http.get(url.getApiUrl('estructura/estructura_plays_by_day'+hashToParams(data)))
      .then(function(res){
        return res.data;
      })
    }
    
    function configEstructuraMarketer(){
      return $http.get(url.getApiUrl('estructura/configuracion_estructura_marketer'))
      .then(function(res){
        return res.data;
      })
    }
    function lockUsers(data){
      return $http.post(url.getApiUrl('estructura/block_user'), data)
      .then(function(res){
        return res.data;
      })
    }
    function unLockUsers(data){
      return $http.post(url.getApiUrl('estructura/unblock_user'),data)
      .then(function(res){
        return res.data;
      })
    }

    function getCintillo(){
      return $http.get(url.getApiUrl('miscelaneos/get_cintillo'))
      .then(function(res){
        return res.data;
      })
    }

    function saveCintillo(data){
      return $http.post(url.getApiUrl('miscelaneos/save_cintillo'),data)
      .then(function(res){
        return res.data;
      })
    }

    function saveAgent(data){
      return $http.post(url.getApiUrl('register/create_agent'), data)
      .then(function(res){
        return res.data;
      })
    }

    function checkUserName(data){
      return $http.post(url.getApiUrl('miscelaneos/check_username'), data)
      .then(function(res){
        return res.data;
      })
    }

    var currency = $resource(url.getApiUrl('currency/:id'), { id: '@_id' }, { 'update': { method: 'PUT' } });

    return {
      getCustomerPlays: getCustomerPlays,
      getSystemVersion: getSystemVersion,
      updateSystemVersion: updateSystemVersion,
      getBancos: getBancos,
      getConfigCoupons:getConfigCoupons,
      saveConfigCoupons: saveConfigCoupons,
      updateConfigCoupons: updateConfigCoupons,
      getReportCoupons: getReportCoupons,
      getRequestNoVerify: getRequestNoVerify,
      verifyRequest: verifyRequest,
      getDefaultRequest: getDefaultRequest,
      saveDefaultRequest: saveDefaultRequest,
      getCouponsTypes: getCouponsTypes,
      getCountsUsers: getCountsUsers,
      getNoVerifyAgents: getNoVerifyAgents,
      verifyAgent: verifyAgent,
      suspendAgent: suspendAgent,
      getEmailsNotifications:getEmailsNotifications,
      toggleStatusEmailsNotifications:toggleStatusEmailsNotifications,
      updateNotificationtime: updateNotificationtime,
      getCouponsBank: getCouponsBank,
      saveCouponBank: saveCouponBank,
      updateCouponBank: updateCouponBank,
      getCustomers: getCustomers,
      getLastOperaciones: getLastOperaciones,
      sendMessage: sendMessage,
      getResumenCajero: getResumenCajero,
      getGames: getGames,
      updateGame: updateGame,
      sendMessageAllCustomers: sendMessageAllCustomers,
      sendDataAutomatic: sendDataAutomatic,
      reverseOperation: reverseOperation,
      getCajeroWeb: getCajeroWeb,
      lockUsers: lockUsers,
      unLockUsers: unLockUsers,
      getCintillo: getCintillo,
      saveCintillo: saveCintillo,
      currency: currency,
      saveAgent: saveAgent,
      checkUserName: checkUserName,
      configEstructuraMarketer: configEstructuraMarketer
    };

  }

})();
